<template>    
  <v-bottom-sheet v-model="dialog">
    <v-sheet flat>        
      <v-card-title>
        <span class="headline">Push</span>
      </v-card-title> 
      <v-divider></v-divider>
      <v-card tile flat style="width:100%; height:340px;">
        <v-card-text style="width:100%; overflow-y:auto; height:260px;">
          <v-row class="ma-n2">
            <v-col cols="4"> <v-chip><h2>Type : {{Item.cType}}</h2></v-chip></v-col>     
            <v-col cols="4">            
              <v-btn :color="getColorAllBtn()" text @click="AllSendClik()"
              ><v-icon class="mr-2" >mdi-check-all</v-icon>ALL Send : {{Item.cALLSend}}</v-btn>
            </v-col>

            <v-col cols="4">            
              <v-btn v-if="Item.cALLSend == 'N'" color="blue darken-1" text @click="MemberSelectClik()"
              ><v-icon class="mr-2">search</v-icon>Member Select: {{Item.ZMember.length}}</v-btn>
            </v-col>
            <v-col cols="12">
              <v-textarea  
                no-resize
                outlined
                flat
                v-model="Item.cMessage"                                                             
                label="Message"
                class="mb-n10"
                placeholder="보낼 메시지를 입력하세요."
                persistent-placeholder
                :maxlength="255"                
              ></v-textarea>
            </v-col>          
          </v-row>        
        </v-card-text>        
        <v-divider></v-divider>
        <v-card-actions>
        <v-btn color="blue darken-1" text @click="save()"
          ><v-icon class="mr-2">mdi-send</v-icon>Send</v-btn>
        <v-spacer></v-spacer> 
        <v-btn color="blue darken-1" text @click="closePage()"
          ><v-icon class="mr-2">mdi-keyboard-backspace</v-icon>Back</v-btn>
      </v-card-actions>
      </v-card>      
    </v-sheet>   
    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <MemberSelect ref="MemberSelectRef" @onSelect="onSelect" />
  </v-bottom-sheet>  
</template>

<script>
// import Util from "../Util.js";
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import MsgBox from "@/components/MsgBox.vue";
import EventBus from '@/eventBus.js';
import MemberSelect from "@/components/MemberSelect.vue";

//푸시메시지 보내기 다이얼로그
export default {
  components: {    
    MsgBox,
    MemberSelect,
  },
  data() {
    return {
      dialog: false,
      sDate : "",      
      model : null,      
      Item : {},            
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage() {                
      this.dialog = false;
    },
    getColorAllBtn(){
      if (this.Item.cALLSend == "Y") {
        return "red darken-1";  
      }
      return "blue darken-1";
    },    
    Show(Item) {       
      // this.cType = Item.cType;
      // this.cSNSBoardNm = Item.cSNSBoardNm;     
      console.log(Item); 
      this.Item = Item;      
      this.dialog = true;      
    },
    onMsgBoxFunc(result) {
      console.log(result);
    },
    onSelect(data){
      console.log(data);
      this.Item.ZMember = data;
    },
    AllSendClik(){
      if (this.Item.cALLSend == "Y") {
        this.Item.cALLSend = "N";
      } else {
        this.Item.cALLSend = "Y";
        this.Item.ZMember = [];
      }
    },
    MemberSelectClik(){
      this.$refs.MemberSelectRef.Show();
    },
    save() {      
      if (this.Item.cALLSend != "Y") {
        if (this.Item.ZMember.length == 0) {
          EventBus.$emit("onShowMsg",true, "보내실 고객을 선택해주세요.") 
          return;
        }
      }

      this.loading = true;   
      this.Item.cUserId = this.$store.state.UserInfo.UserId;

      axios
        .post(BasicInfo.UIL_API + "SendPushReq", this.Item, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "전송되었습니다.");
            this.closePage();
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;   
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;   
        });
    },
  }
};
</script>
