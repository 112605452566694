<template>
  <div>       
    <PWChange ref="PWChangeRef" />
    <div v-if="isLogin" >
      <v-hover v-slot="{ hover }">
        <v-app-bar app dense clipped-left :elevation="hover ? 8 : 2"  style="z-index: 22;">
          <v-app-bar-nav-icon color="black" @click="drawer = !drawer"/>  
            <v-toolbar-title color="grey darken-1">      
              <div class="d-flex">
                현대 안전관리 시스템 - 잠실종합운동장 리모델링 현장
                <!-- <v-btn icon small class="ml-n3 mt-2 mr-2" @click="sendLLM_ChatFunctionCall()"><v-icon dark>mdi-microphone</v-icon></v-btn>
                <v-btn icon small class="ml-0 mt-2 mr-2" @click="showChat()"><v-icon dark>mdi-robot</v-icon></v-btn> -->
                
                <!-- <v-select class="mt-4 mb-n4" dense 
                  v-model="UserInfo.NowSiteId" 
                  label="사이트 선택" 
                  placeholder="사이트를 등록하세요."
                  persistent-placeholder
                  v-on:change="ChangeSite"
                  :items="zSiteInfo" item-text="sNm" item-value="sId"></v-select>
                <v-btn icon class="ml-1"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>                 -->
              </div>                   
            </v-toolbar-title>      
            <v-spacer></v-spacer>
            <div class="d-flex">              
              <v-chip label style="margin-top: 2px;" >{{sNowDatetime}}</v-chip>
              <v-icon class="ml-2">account_circle</v-icon>
              <div class="pa-1">{{ UserInfo.UserNm }}님</div>
              <!-- <v-badge
                bordered
                content="6"
                top
                color="red"                
                offset-x="10"
                offset-y="18"
              >
                <v-icon style="margin-top: 5px;">
                  mdi-bell
                </v-icon>
              </v-badge> -->
              
              <!-- <IronSwitch class="ml-4" :zCaptions="['KOR','ENG']" @onChange="onChangeLang"></IronSwitch> -->
            </div>
          
        </v-app-bar>   
      </v-hover>
    </div>

    <v-navigation-drawer v-model="drawer" expand-on-hover app clipped v-if="isLogin" dense width=220 style="z-index: 21;">        
      <div class="text-center mt-2">                 
        <img src="@/assets/logo.svg" style="width : 40px" />
      </div>
      
      <v-divider></v-divider>
      
      <v-list nav dense>        
        <v-list-item :to="sHomeUrl" link>
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">현황판</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/Monitoring'" link>
          <v-list-item-icon>
            <v-icon>mdi-monitor</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">모니터링</v-list-item-title>
        </v-list-item>

        <!-- 기본리스트를 태그로 설정하고 포함되는 리스트는 data형태로 받아옴 --> 
        <v-list-group prepend-icon="mdi-cog" no-action v-if="MenuInfo.isShowMenu_BasicSetting" color="grey darken-2">
          <template v-slot:activator>
            <v-list-item-title class="ml-n5">기본설정</v-list-item-title>
          </template>
          <v-list-item v-for="(basicSetting, i) in MenuInfo.basicSetting" :key="i" :to="basicSetting.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="basicSetting.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="basicSetting.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>
              
        <v-list-group prepend-icon="mdi-account-details" no-action v-if="MenuInfo.isShowMenu_UserManage" color="grey darken-2">        
          <template v-slot:activator>          
              <v-list-item-title class="ml-n5">인원관리</v-list-item-title>          
          </template>        
          <v-list-item v-for="(userManage, i) in MenuInfo.userManage" :key="i" :to="userManage.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="userManage.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="userManage.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>     

        <v-list-group prepend-icon="mdi-access-point-network" no-action v-if="MenuInfo.isShowMenu_EquipManage" color="grey darken-2">        
          <template v-slot:activator>          
              <v-list-item-title class="ml-n5">장비관리</v-list-item-title>          
          </template>        
          <v-list-item v-for="(equipManage, i) in MenuInfo.equipManage" :key="i" :to="equipManage.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="equipManage.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="equipManage.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-map" no-action v-if="MenuInfo.isShowMenu_ZoneManage" color="grey darken-2">        
          <template v-slot:activator>          
              <v-list-item-title class="ml-n5">구역관리</v-list-item-title>          
          </template>        
          <v-list-item v-for="(zoneManage, i) in MenuInfo.zoneManage" :key="i" :to="zoneManage.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="zoneManage.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="zoneManage.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>   

        <v-list-group prepend-icon="mdi-file-document" no-action v-if="MenuInfo.isShowMenu_LogManage" color="grey darken-2">        
          <template v-slot:activator>          
              <v-list-item-title class="ml-n5">이력관리</v-list-item-title>          
          </template>        
          <v-list-item v-for="(logManage, i) in MenuInfo.logManage" :key="i" :to="logManage.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="logManage.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="logManage.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-card-account-details-outline" no-action v-if="MenuInfo.isShowMenu_EnterManage" color="grey darken-2">        
          <template v-slot:activator>          
              <v-list-item-title class="ml-n5">출입관리</v-list-item-title>          
          </template>        
          <v-list-item v-for="(enterManage, i) in MenuInfo.enterManage" :key="i" :to="enterManage.to">
            <v-list-item-icon>
              <v-icon class="ml-n5" v-text="enterManage.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n10" v-text="enterManage.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>         

        <v-divider></v-divider>            
        <!-- <v-list-item v-if="UserInfo.UserId == 'root'" to="/LogView">
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">이벤트 로그</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="UserInfo.UserId == 'root'" to="/LogEventView">
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">API 로그</v-list-item-title>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list-item @click="$refs.PWChangeRef.open()">
          <v-list-item-icon>
            <v-icon>mdi-multiplication-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">비밀번호 수정</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$store.dispatch('logout')">
          <v-list-item-icon>
            <v-icon>mdi-login-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">로그아웃</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <!-- <LLM_Chatbot ref="LLM_ChatbotRef"></LLM_Chatbot>
    <LLM_ChatFunction ref="LLM_ChatFunctionRef"></LLM_ChatFunction> -->
  </div>  
</template>

<script>
import router from "@/router/index"
import {mapState} from "vuex";
import PWChange from '@/views/PWChange.vue';
import axios from "axios"
import Util from "@/Util.js";
// import IronSwitch from "@/components/IronSwitch.vue";  
import EventBus from "@/eventBus.js";
// import LLM_Chatbot from "@/components/LLM_Chatbot.vue";
// import LLM_ChatFunction from "@/components/LLM_ChatFunction.vue";

export default {
  components: { 
    PWChange, 
    // IronSwitch,    
    // LLM_Chatbot,
    // LLM_ChatFunction,
  },
  computed: {
     ...mapState(["isLogin", "UserInfo", "MenuInfo", "zSiteInfo"]),    
  },
  props: {    
  },
  data: () => ({
    isKor : true,
    drawer: null,
    sHomeUrl :"/",
    version : "Ver. 1.0.1",
    sNowDatetime : "",    
  }),

  beforeDestroy(){
    EventBus.$off('onLLM_Chat');
  },  
  
  mounted () {      
    EventBus.$on("onLLM_Chat", (cType, cKeyword) => {    
      this.showChat();

      setTimeout(() => {
        this.$refs.LLM_ChatbotRef.SendLLM(cType, cKeyword)
      }, 100);
    });

    this.SetTimeDisplay();
    this.version = localStorage.getItem("Version");
    // console.log('current version :', this.version);  
    
    // 버전업이 되었으면 버전을 올리도록 처리  
    
      // console.log('version update check...');        
      axios
        .get(`/version.json?timestamp=${new Date().getTime()}`)
        .then(res => {  
          
        // console.log('server version :', res.data.version);  
    
    
        if (res.data.version != this.version) {              
          if (this.version != "") {
            this.deleteCash();                      
          } 
          localStorage.setItem("Version", res.data.version);                       
          this.version = res.data.version;
        }  
      });
  },
  methods: {    
    onChangeLang(a){
      this.isKor = a;        
      
    },
    ChangeSite(){      
      this.UserInfo.SiteNm = Util.sGetNmRecord(this.zSiteInfo, this.UserInfo.SiteId);
      localStorage.setItem("UserInfo.NowSiteId", this.UserInfo.NowSiteId);
      localStorage.setItem("UserInfo.NowSiteNm", this.UserInfo.NowSiteNm);
      this.goMyPage();  
    },
    goMyPage() {
      if (router.history.current.name == "Dashboard") {
        EventBus.$emit("onDashboard");
      } else {
        router.push({ name: "Dashboard" });
      }
      
    },
    goLogin() {
      router.push({ name: "Login" });
    },
    SetTimeDisplay(){
      this.sNowDatetime = Util.sDateTime(new Date());
      setTimeout(() => {        
        this.SetTimeDisplay();      
      }, 1000);
    },

    showChat(){
      this.$refs.LLM_ChatbotRef.Show();
    },
    sendLLM_ChatFunctionCall(){
      this.$refs.LLM_ChatFunctionRef.Show();
    },
    

    deleteCash(){
//캐쉬 지우기      
      // caches  
      //   .keys()  
      //   .then(c => {  
      //     for (const i of c) {  
      //       caches.delete(i);                  
      //     }  
      //   })  
      //   .then(() => {                              
      //     location.reload(true);  
      //   });            
      //   console.log('update app...');     
    },
  }     
}
</script>