<template>  
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title style="font-size: 15px; color:#000000A0" >
          <v-icon small>mdi-alert-circle-outline</v-icon>
          <div class = "ml-1" v-html="sTitle"></div>
        </v-card-title>
        
        <v-card-text class="mt-4 text-center"><div  style="font-size: 18px;" v-html="sMsg"></div></v-card-text>
        <v-divider class="ml-4 mr-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="GoResult(false)" class="font-weight-bold">
            <v-icon class="mr-2">cancel</v-icon>취소
          </v-btn>
          <v-btn color="blue darken-1" text @click="GoResult(true)" class="font-weight-bold">
            <v-icon class="mr-2">check_circle</v-icon>확인
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      sTitle: "",
      sMsg: "",
    };
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown, false);      
  },
  methods: {    
    GoResult: function(result) {
      this.dialog = false;
      this.$emit("onMsgBox", result);
    },
    
    RunMsgBoxShow: function(sTitle, sMsg) {       
      var s = sMsg.toString(); 
      this.sTitle = sTitle.replace(/(?:\r\n|\r|\n)/g, '<br />');
      this.sMsg = s.replace(/(?:\r\n|\r|\n)/g, '<br />');
      this.dialog = true;
    },

    onKeyDown(event){  
      if (event.code == "Escape") {
        if (this.dialog) {
          this.dialog = false;
        }
      }
      if (event.key == "Enter") {        
        if (this.dialog) {
          this.GoResult(true);     
        }        
      }
    },
  }
};
</script>
