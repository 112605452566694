module.exports = {
    // UIL_API: "http://127.0.0.1:9787/",    
    // FILE_API: "http://127.0.0.1:9787/DownloadFile?FileUrl=",  //File System        
    UIL_API: "http://211.188.61.249:9787/",    
    FILE_API: "http://211.188.61.249:9787/DownloadFile?FileUrl=",  //File System        
    // UIL_API: "https://hios.co.kr:9787/",    
    // FILE_API: "https://hios.co.kr:9787/DownloadFile?FileUrl=",  //File System        
    IsReqMode  : false,  //출입예약 모드
    Is3D_MapMode : true,  //3D Map Mode
    IsPOS_Near : false,  //근처인원 표현 여부
    Title : "HIoS",
    SiteId : "SJ",

    //FILE_API: "",    //File cloud Service
    Total_Day_Duration: 3600 * 20,
    Map_Zoom_Default: 1, 

    //파일유형
    FILE_TYPE_VIDEO : "10",
    FILE_TYPE_IMAGE : "20",
    FILE_TYPE_AUDIO : "30",

    // CODE_OTHER   : "OTHER",  
    // CORP_DEFAULT : "CIC",       
    // CORPS : ["CIC", "SKST", "SKMP"] ,       

    REQ_ENTER_RESULT_REQ : "REQ",   //요청
    REQ_ENTER_RESULT_OK  : "OK",     //승인
    REQ_ENTER_RESULT_NG  : "NG",    //미승인
    REQ_ENTER_RESULT_HOLD : "HOLD", //보류
};