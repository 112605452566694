<template>    
  <v-dialog v-model="isSensorInfo" width="90%">
    <v-card>
      <v-card-title>센서 정보 : {{ cCaption }} <v-spacer></v-spacer> <v-btn class="mr-n2" icon @click="isSensorInfo = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>

        <v-chart v-if="isCardShow" class="chartTrend" :option="optionTrend" />
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
import BasicInfo from "@/BasicInfo.js";
import axios from "axios";
import EventBus from '@/eventBus.js';

import { createProj, addProj } from 'vuelayers/lib/ol-ext'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from 'echarts/components';
import { PieChart, LineChart, BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  GridComponent,
  PieChart,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,  
]);

let size = [1280, 1280];
let extent = [0, 0, ...size];


let customProj = createProj({
  code: 'xkcd-image',
  units: 'pixels',
  extent,
})


addProj(customProj);



export default {
  components: {    
    VChart,    
  },
  data() {
    return {      
      isSensorInfo : false,  
      isCardShow : false,    
      cCaption : "",
      optionTrend: {     
      tooltip: {
        trigger: "axis",        
      },
      legend: {
        data: ['산소(%)', '일산화탄소(ppm)', '황화수소(ppm)', '메탄(%)', '이산화탄소(ppm)']
      },
      xAxis:{
        type: 'category',        
        data: ['10', '10', '10', '10', '10', '10', '10', '','','','', '', '']
      },
      yAxis:{
        type : 'value',
        // name : 'value',
      },
      series: [
        {name: "산소(%)", smooth: true, type: "line", data: [], markPoint:{data:[{type:'max', name:'max'}],},markLine:{data:[{type:'average', name:'avg'}],}},
        {name: "일산화탄소(ppm)", smooth: true, type: "line", data: [], markPoint:{data:[{type:'max', name:'max'}],},markLine:{data:[{type:'average', name:'avg'}],}},
        {name: "황화수소(ppm)", smooth: true, type: "line", data: [], markPoint:{data:[{type:'max', name:'max'}],},markLine:{data:[{type:'average', name:'avg'}],}},
        {name: "메탄(%)", smooth: true, type: "line", data: [], markPoint:{data:[{type:'max', name:'max'}],},markLine:{data:[{type:'average', name:'avg'}],}},
        {name: "이산화탄소(ppm)", smooth: true, type: "line", data: [], markPoint:{data:[{type:'max', name:'max'}],},markLine:{data:[{type:'average', name:'avg'}],}},
      ]
    },
    };
  },  
  created() {    
  },
  

  
  mounted () {    
  },
  methods: {    
    Show(cSensorId) {
      this.isCardShow = false; 
      this.isSensorInfo = true;

      setTimeout(() => {
        this.GetData(cSensorId);       
        
      }, 100);
    },

    GetData(cSensorId) {           
      this.optionTrend.xAxis.data = [];
      for(let j=0; j < this.optionTrend.series.length; j++){
        this.optionTrend.series[j].data = [];
      } 

      var posData = {                 
        cNowSiteId : this.$store.state.UserInfo.NowSiteId,
        cSensorId : cSensorId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetSensorLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token,            
          }
        })
        .then(res => {
          
          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }

          this.cCaption = res.data.Result1[0].cMapFullNm + " / " + res.data.Result1[0].cAPId;

          for(let i = 0; i < res.data.Result2.length; i++) {
            this.optionTrend.xAxis.data.push(i);              

            this.optionTrend.series[0].data.push([i, Math.round(res.data.Result2[i].cSENS_OXYGEN_Data)]);
            this.optionTrend.series[1].data.push([i, res.data.Result2[i].cSENS_CARBON_MONOXIDE_Data]);
            this.optionTrend.series[2].data.push([i, res.data.Result2[i].cSENS_HYDROGEN_Data]);
            this.optionTrend.series[3].data.push([i, res.data.Result2[i].cSENS_METHAN_Data]);
            this.optionTrend.series[4].data.push([i, res.data.Result2[i].cSENS_CARBON_DIOXIDE_Data]);
          }                                             

          this.isCardShow = true; 
        })
        // .catch(err => {
        //   EventBus.$emit("onErrorSnackbar", "(03)"+err);
        // });
      //
    },   
  }     
};

</script>

<style scoped>
  .chartTrend {
    height: 300px;    
  }  
</style>