<template>
  <div >
    <!-- 정보를 확인해주는 아래쪽에 스낵바 띄우기 -->        
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout"> {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon @click="snackbar = false" v-bind="attrs"><v-icon>close</v-icon></v-btn>
      </template>
    </v-snackbar>

    <!-- 에러를 나타내는 다이어로그 띄우기 -->   
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>          
          <v-card-title style="font-size: 15px;">
            <v-icon v-if="isError" :color="color" small >mdi-alert-circle-outline</v-icon>
            <v-icon v-else >mdi-alert-circle</v-icon>
            <div class="ml-1" v-if="isError" v-html="sTitle" style="color:#ff0000A0; margin-top: -2px;"></div>
            <div class="ml-1" v-else v-html="sTitle" style="color:#000000A0; margin-top: -2px;"></div>
            <!-- <div v-html="sTitle" :style="'color:' + isError?'#ff000090':'#888888'"></div> -->
          </v-card-title>
            
          
          <v-card-text class="mt-5 text-center" style="font-size: 18px;"><div v-html="sMsg"></div></v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="red darken-1" text @click="GoResult()" class="font-weight-bold"><v-icon class="mr-2">check</v-icon>확인</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>  
</template>


<script>
  export default {
    data: () => ({
      snackbar: false,
      color: '',
      text: '----',
      timeout: 5000,
      dialog: false,
      sTitle: "",
      sMsg: "",
      isError : false,
    }),     
    mounted() {
      window.addEventListener("keydown", this.onKeyDown, false);      
    },
    methods : {      
      GoResult: function() {
        this.dialog = false;      
      },

      
      RunMsgBoxShow: function(sTitle, sMsg) {        
        this.sTitle = sTitle.replace(/(?:\r\n|\r|\n)/g, '<br />');
        this.sMsg = sMsg.replace(/(?:\r\n|\r|\n)/g, '<br />');
        this.dialog = true;
      },

      
      ShowMsg: function(isErr, sMsg) {        
        var s = sMsg.toString(); 
        if (isErr === true) {
          this.color = "error";
          this.isError = true;
          this.RunMsgBoxShow("Error", s);
        } else {
          this.color = "info"
          this.isError = false;
          this.text = s;            
          this.snackbar = true;
        }
      },

      ShowSnackbar(sMsg) {
        this.color = "error"
        this.isError = false;
        this.text = sMsg;            
        this.snackbar = true;
      },
      
      ShowCommResult: function(sMsg) {        
        var s = sMsg.toString();
        this.color = "info"
        this.isError = false;
        this.RunMsgBoxShow("Result", s);
      },

      onKeyDown(event){             
        if (event.code == "Escape") {
          this.dialog = false;
        }        
      },
    } 
  }
</script>

