import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import BasicInfo from "../BasicInfo.js";
import axios from "axios";
import EventBus from "@/eventBus.js";

Vue.use(Vuex);

function GetPromgramUserRoleInfo(userId, programId) {
  var posData = {
    UserId: userId,
    ProgramId: programId,
  }
  return new Promise((resolve) => (
    axios
    .post(BasicInfo.UIL_API + "GetPromgramUserRoleInfo", posData, {
      headers: {
        "Authorization": localStorage.getItem("UserInfo.Token")
      }
    })
    .then(res => {
      resolve(res);
    }).catch(err => {
      resolve(null);
      console.log(err)
    })
  ));
}

export default new Vuex.Store({
  state: {
    UserInfo: {
      UserId: "",
      UserNm: "",
      Token: "",
      Lev: "",
      CorpId: "",
      CorpNm: "",      
      PartNm: "",           
      MapNms: "",            
      zMapGroupId: [],
      zBuildingId: [],
      zMapId: [],
      zMapIdAll: [],
      zProgramId : [],
      isSSO : false,
      isGroupMode : false,
      isWorker : true,
      NowSiteId : "00001",
      SiteId : "00001",
      SiteNm : "NONE",
    },    

    zSiteInfo: [],  

    MenuInfo: {
      isShowMenu_BasicSetting : false,
      isShowMenu_UserManage : false,
      isShowMenu_EquipManage : false,
      isShowMenu_ZoneManage : false,
      isShowMenu_LogManage : false,
      isShowMenu_EnterManage : false,
  
      isShowMenu_EnterSiteList : false,
      isShowMenu_EnterUserLog : false,    

      basicSetting : [],
      userManage: [], 
      equipManage : [],
      zoneManage : [],
      logManage : [],
      enterManage : [],

      basicSetting_tmp : [      
        { isShowMenu : false, text: "권한관리", icon: "mdi-account-check", to: "/AuthorityInfo" },
        { isShowMenu : false, text: "알림설정", icon: "mdi-alert-circle", to: "/AlarmSetting" },
      ],
      userManage_tmp: [
        // { isShowMenu : false, text: "구성원관리", icon: "mdi-account-edit", to: "/IdInfo" },
        { isShowMenu : false, text: "작업자관리", icon: "mdi-account-arrow-right", to: "/WorkerInfo" },      
        { isShowMenu : false, text: "태그매핑", icon: "mdi-account-network", to: "/TagMapping" },      
        { isShowMenu : false, text: "태그매핑 이력조회", icon: "mdi-account-search", to: "/UserTagMappingLog" },  
      ], 
      equipManage_tmp : [
        { isShowMenu : false, text: "AP", icon: "mdi-access-point", to: "/EquipSettingAp" },
        { isShowMenu : false, text: "Tag", icon: "mdi-card-account-details-outline", to: "/EquipSettingTag" }, 
      ],
      zoneManage_tmp : [      
        { isShowMenu : false, text: "구조정보 관리", icon: "mdi-home-city", to: "/ZoneInfo" },
        { isShowMenu : false, text: "AP위치 관리", icon: "mdi-map-marker-radius", to: "/APInfo" },                  
        { isShowMenu : false, text: "직원구역맵핑이력", icon: "mdi-map-check", to: "/ZoneUserMapping" },     
      ],
      logManage_tmp : [
        { isShowMenu : false, text: "이벤트 이력관리", icon: "mdi-information", to: "/EventLog" },
        // { isShowMenu : false, text: "비상호출 조치결과", icon: "mdi-image-search", to: "/SOSResView" },      
        { isShowMenu : false, text: "시스템 로그", icon: "description", to: "/LogView" },
        { isShowMenu : false, text: "API 로그", icon: "mdi-checkbox-marked", to: "/LogEventView" },
      ],
      enterManage_tmp : [      
        // { isShowMenu : false, text: "대리출입신청", icon: "mdi-card-account-details-star-outline", to: "/EnterReqAgent" }, 
        // { isShowMenu : false, text: "출입승인", icon: "mdi-card-account-details", to: "/EnterPermission" }, 
        // { isShowMenu : false, text: "단지출입목록조회", icon: "mdi-map-clock", to: "/EnterMapGroupList" }, 
        { isShowMenu : false, text: "구역출입 목록", icon: "mdi-database-sync", to: "/EnterSiteList" }, 
        { isShowMenu : false, text: "출입인원 현황조회", icon: "mdi-database-search", to: "/EnterUserLog" }, 
      ],
    },

    isLogin: false,
    isLoginError: false,
    
    zCorpAll: [{sId:"ALL", sNm:"모두"}, {sId:"SKMR", sNm:"SKMR"}, {sId:"OTHER", sNm:"협력업체"}],  
    zCorp: [{sId:"NONE", sNm:"없음"}],  
    zIsEmployed: [{sId:"Y", sNm:"재직"}, {sId:"N", sNm:"퇴사"}],  
    zUse: [{sId:"Y", sNm:"사용"}, {sId:"N", sNm:"미사용"}],      
    zUseALL: [{sId:"ALL", sNm:"모두"}, {sId:"Y", sNm:"사용"}, {sId:"N", sNm:"미사용"}],      

    zUseDevice: [{sId:"Y", sNm:"사용"}, {sId:"N", sNm:"미사용"}, {sId:"E", sNm:"불량"}],      
    zUseALLDevice: [{sId:"ALL", sNm:"모두"}, {sId:"Y", sNm:"사용"}, {sId:"N", sNm:"미사용"}, {sId:"E", sNm:"불량"}],      

    zBattery: [{sId:"ALL", sNm:"모두"}, {sId:"0", sNm:"0%"}, {sId:"1", sNm:"~10%"}, {sId:"2", sNm:"~20%"}, {sId:"3", sNm:"~30%"}, {sId:"4", sNm:"~40%"}, {sId:"5", sNm:"~50%"}, {sId:"6", sNm:"~60%"}, {sId:"7", sNm:"~70%"}, {sId:"8", sNm:"~80%"}, {sId:"9", sNm:"~90%"}, {sId:"A", sNm:"~100%"}],      
    zMapping: [{sId:"Y", sNm:"매핑중"}, {sId:"N", sNm:"매핑해제"}],  
    zMappingAll: [{sId:"A", sNm:"모두"}, {sId:"Y", sNm:"배정"}, {sId:"N", sNm:"미배정"}],  
    zApState: [{sId:"ON", sNm:"정상"}, {sId:"OFF", sNm:"꺼짐"}, {sId:"ERR", sNm:"에러"}],      
    zApInOutType: [{sId:"NONE", sNm:"일반"}, {sId:"IN", sNm:"IN"}, {sId:"OUT", sNm:"OUT"}, ],  
    zDeviceType: [{sId:"BLE", sNm:"BLE"}, {sId:"Wifi", sNm:"Wifi"}],  
    zReqStateTypeAll : [{sId:"ALL", sNm:"모두"}, {sId:"REQ", sNm:"신청중"}, {sId:"OK", sNm:"승인"}, {sId:"NG", sNm:"반려"}, {sId:"HOLD", sNm:"보류"},  {sId:"NONE", sNm:"없음"},],  
    zReqStateType : [{sId:"REQ", sNm:"신청중"}, {sId:"OK", sNm:"승인"}, {sId:"NG", sNm:"반려"}, {sId:"HOLD", sNm:"보류"}, {sId:"NONE", sNm:"없음"},],  
    zCheckAll: [{sId:"ALL", sNm:"모두"},{sId:"Y", sNm:"조치"}, {sId:"N", sNm:"미조치"}],  
    zUserRoleType: [{sId:"N", sNm:"일반"}, {sId:"M", sNm:"관리자"}],  
    zSOSResult: [{sId:"00", sNm:"단순실수"}, {sId:"01", sNm:"응급상황"}, {sId:"99", sNm:"조치결과 직접입력"}],  
    zEnterType: [{sId:"ALL", sNm:"모두"}, {sId:"G", sNm:"일반출입"}, {sId:"J", sNm:"작업출입"}],  

    zBuildingType: [{sId:"N", sNm:"일반"}, {sId:"B", sNm:"분석실"}],  

    zGroupAll: [{sId:"ALL", sNm:"모두"},],  
    zGroup: [{},],  
    

    zDeviceTypeInfoAll: [{sId:"ALL", sNm:"모두"},],
    zDeviceTypeInfo: [{},],  

    zDeviceEventAll : [
      {sId : "ALL", sNm : "모두"},
      // {sId : "EV0101", sNm : "비상호출"},
      // {sId : "EV02", sNm : "비상호출해제"},
      {sId : "EV0102", sNm : "비인가 구역 진입"},
      {sId : "EV04", sNm : "장비 통신상태 불량 이벤트"},
      {sId : "EV05", sNm : "배터리 잔량 기준 이하 경고"},
      {sId : "EV06", sNm : "배터리 잔량 기준 이하 교체"},
    ],
    UserRoleInfo: [{sId:"NONE", sNm:"NONE"}],

    CdGroupInfo: {
      zNm: [],
      zId: [],
      zNmNoAll: [],
      zIdNoAll: [],
    },
    
    ProgramPermissionInfo: {
      okC: false,
      okR: false,
      okU: false,
      okD: false,
      ReqProgram: "",
      ProgramNm: "",
      back: "",
    },
    // FileTypeInfo : {
    //   zNm: ['10-동영상','20-이미지','30-음악'],      
    //   zId: ['10', '20', '30'],
    // },
    
    //각 페이지별로 CRUD 권한 체크
    async iGetPromgramUserRoleInfo(caption, programId, back) {
      this.ProgramPermissionInfo.ReqProgram = programId + "-" + caption; 
      this.ProgramPermissionInfo.okC = false;
      this.ProgramPermissionInfo.okR = false;
      this.ProgramPermissionInfo.okU = false;
      this.ProgramPermissionInfo.okD = false;
      this.ProgramPermissionInfo.back = back;      
      
      var res = await GetPromgramUserRoleInfo(this.UserInfo.UserId, programId);      
      if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
      if (res.data.ResultCd === "00") {
        this.ProgramPermissionInfo.okC = (res.data.Result[0].cOkC == "Y");
        this.ProgramPermissionInfo.okR = (res.data.Result[0].cOkR == "Y");
        this.ProgramPermissionInfo.okU = (res.data.Result[0].cOkU == "Y");
        this.ProgramPermissionInfo.okD = (res.data.Result[0].cOkD == "Y");
        this.ProgramPermissionInfo.ProgramNm = res.data.Result[0].ProgramNm;

        if (this.ProgramPermissionInfo.okR) {
          return 0;
        } else {
          return -1;
          }
                  
      } else if (res.data.ResultCd === "44") { //토큰이 없을시
        return -2;
      } else {
        return -1;
      }
      
    },

    ShowMenuCheck(){
      this.MenuInfo.basicSetting = [];
      this.MenuInfo.userManage = [];
      this.MenuInfo.equipManage = [];
      this.MenuInfo.zoneManage = [];
      this.MenuInfo.logManage = [];
      this.MenuInfo.enterManage = [];

      var bFind = false;

      for(let i in this.MenuInfo.basicSetting_tmp){
        if (this.isMenu(this.MenuInfo.basicSetting_tmp[i].to)){
          this.MenuInfo.basicSetting.push(this.MenuInfo.basicSetting_tmp[i]);
          bFind = true;
        }
      }
      this.MenuInfo.isShowMenu_BasicSetting = bFind;

      bFind = false;
      for(let i in this.MenuInfo.userManage_tmp){
        if (this.isMenu(this.MenuInfo.userManage_tmp[i].to)){
          this.MenuInfo.userManage.push(this.MenuInfo.userManage_tmp[i]);
          bFind = true;
        }
      }      
      this.MenuInfo.isShowMenu_UserManage = bFind;


      bFind = false;
      for(let i in this.MenuInfo.equipManage_tmp){
        if (this.isMenu(this.MenuInfo.equipManage_tmp[i].to)){
          this.MenuInfo.equipManage.push(this.MenuInfo.equipManage_tmp[i]);
          bFind = true;
        }
      }      
      this.MenuInfo.isShowMenu_EquipManage = bFind;


      bFind = false;
      for(let i in this.MenuInfo.zoneManage_tmp){
        if (this.isMenu(this.MenuInfo.zoneManage_tmp[i].to)){
          this.MenuInfo.zoneManage.push(this.MenuInfo.zoneManage_tmp[i]);
          bFind = true;
        }
      }      
      this.MenuInfo.isShowMenu_ZoneManage = bFind;

      bFind = false;
      for(let i in this.MenuInfo.logManage_tmp){
        if (this.isMenu(this.MenuInfo.logManage_tmp[i].to)){
          this.MenuInfo.logManage.push(this.MenuInfo.logManage_tmp[i]);
          bFind = true;
        }
      }      
      this.MenuInfo.isShowMenu_LogManage = bFind;

      bFind = false;
      for(let i in this.MenuInfo.enterManage_tmp){
        if (this.isMenu(this.MenuInfo.enterManage_tmp[i].to)){
          this.MenuInfo.enterManage.push(this.MenuInfo.enterManage_tmp[i]);
          bFind = true;
        }
      }      
      this.MenuInfo.isShowMenu_EnterManage = bFind;
    },

    isMenu(sId){
      for(let i in this.UserInfo.zProgramId) {
        if (this.UserInfo.zProgramId[i] == sId) {          
          return true;
        }
      }

      return false;
    },
    
    GetCorpInfo() {      
      this.zCorp =  [];     

      var posData = {
        cUserId: this.UserInfo.UserId,        
        cNowSiteId: this.UserInfo.NowSiteId,        
        cUseCheck: "Y",
      }

      var config = {
        headers: {
          "Authorization": this.UserInfo.Token,
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetCorpInfo", posData, config)
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (res.data.Result.length == 0) {
              this.zCorp =  [{sId:"NONE", sNm:"없음"}];
            } else {
              for (var i = 0; i < res.data.Result.length; i++) {
                this.zCorp.push({sId:res.data.Result[i].cCorpId, sNm:res.data.Result[i].cCorpNm});
              }
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },


    GetDeviceTypeInfo() {      
      this.zDeviceTypeInfo =  [];     
      this.zDeviceTypeInfoAll =  [];     

      var posData = {
        cUserId: this.UserInfo.UserId,        
        cUseCheck: "Y",
      }

      var config = {
        headers: {
          "Authorization": this.UserInfo.Token,
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetDeviceTypeInfo", posData, config)
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (res.data.Result.length == 0) {
              this.zDeviceTypeInfo =  [{sId:"NONE", sNm:"없음"}];
              this.zDeviceTypeInfoAll =  [{sId:"NONE", sNm:"없음"}];
              
            } else {
              for (var i = 0; i < res.data.Result.length; i++) {
                if (res.data.Result[i].cDeviceTypeId == "ALL") {
                  this.zDeviceTypeInfoAll.push({sId:res.data.Result[i].cDeviceTypeId, sNm:res.data.Result[i].cDeviceTypeNm});
                } else {
                  this.zDeviceTypeInfo.push({sId:res.data.Result[i].cDeviceTypeId, sNm:res.data.Result[i].cDeviceTypeId + "-" + res.data.Result[i].cDeviceTypeNm});
                  this.zDeviceTypeInfoAll.push({sId:res.data.Result[i].cDeviceTypeId, sNm:res.data.Result[i].cDeviceTypeId + "-" + res.data.Result[i].cDeviceTypeNm});
                }                
              }
            }
          }
          
        }).catch(err => {
          console.log(err)
        })
    },
    

    GetGroupInfo() {      
      this.zGroup =  [];     
      this.zGroupAll =  [];     

      var posData = {
        cUserId: this.UserInfo.UserId,        
        cUseCheck: "Y",
      }

      var config = {
        headers: {
          "Authorization": this.UserInfo.Token,
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetGroupInfo", posData, config)
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (res.data.Result.length == 0) {
              this.zGroup =  [{sId:"NONE", sNm:"없음"}];
              this.zGroupAll =  [{sId:"NONE", sNm:"없음"}];
              
            } else {
              for (var i = 0; i < res.data.Result.length; i++) {
                if (res.data.Result[i].cGroupId == "ALL") {
                  this.zGroupAll.push({sId:res.data.Result[i].cGroupId, sNm:res.data.Result[i].cGroupNm});
                } else {
                  this.zGroup.push({sId:res.data.Result[i].cGroupId, sNm:res.data.Result[i].cGroupId + "-" + res.data.Result[i].cGroupNm});
                  this.zGroupAll.push({sId:res.data.Result[i].cGroupId, sNm:res.data.Result[i].cGroupId + "-" + res.data.Result[i].cGroupNm});
                }
                
              }
            }
          }

          console.log(this.zGroup);
        }).catch(err => {
          console.log(err)
        })
    },
    
    GetIsWorker(){      
      if (this.UserInfo.isGroupMode) {
        if (this.UserInfo.CorpId == 'ALL'){          
          this.UserInfo.isWorker = false;
          return;
        }
        if (this.UserInfo.CorpId == 'NONE'){          
          this.UserInfo.isWorker = false;
          return;
        }

        for (let i = 0; i < BasicInfo.CORPS.length; i++) {
          if (this.UserInfo.CorpId == BasicInfo.CORPS[i]){
            this.UserInfo.isWorker = false;
            return;
          }          
        }
        this.UserInfo.isWorker = true;        
      } else {
        this.UserInfo.isWorker =  !((this.UserInfo.CorpId == BasicInfo.CORP_DEFAULT && this.UserInfo.Lev == 'M') || this.UserInfo.CorpId == 'NONE' || this.UserInfo.CorpId == 'ALL');
      }            
    }
  },
  mutations: {
    loginSucess(state, pay) {
      var payload = pay.Result1[0];
      var zProgram = pay.Result2;      

      state.isLogin = true;
      state.isLoginError = false;
      state.UserInfo.UserId = payload.UserId;

      if (payload.Token !== "" && payload.Token !== null && payload.Token !== undefined) {      

        state.UserInfo.Token = payload.Token;
      }

      state.UserInfo.isGroupMode = payload.GroupMode == 'Y';
      state.UserInfo.GroupId = payload.GroupId;


      state.UserInfo.Lev = payload.cLev;
      state.UserInfo.SiteId = payload.cSiteId;
      state.UserInfo.UserNm = payload.UserNm;      
      state.UserInfo.PartNm = payload.PartNm;
      state.UserInfo.CorpId = payload.CorpId;
      state.UserInfo.CorpNm = payload.CorpNm;      

      state.UserInfo.MapNms = payload.MapNms;
      state.UserInfo.zMapGroupId = [];
      state.UserInfo.zBuildingId = [];
      state.UserInfo.zMapId = [];
      state.UserInfo.zMapIdAll = [];
      var zMapCds = payload.MapCds.split(",");

      for (let i in zMapCds) {
        state.UserInfo.zMapIdAll.push(zMapCds[i]);
        state.UserInfo.zMapGroupId.push(zMapCds[i].substring(0,2));
        state.UserInfo.zBuildingId.push(zMapCds[i].substring(2,4));
        state.UserInfo.zMapId.push(zMapCds[i].substring(4,6));
      }      

      state.UserInfo.zProgramId = [];
      for (let i in zProgram) {
        state.UserInfo.zProgramId.push("/" + zProgram[i].cProgramId);
      }

      localStorage.setItem("UserInfo.UserId", state.UserInfo.UserId);

      if (payload.Token !== "" && payload.Token !== null && payload.Token !== undefined) { 
        localStorage.setItem("UserInfo.Token", state.UserInfo.Token);
      }      

      
      state.zSiteInfo = [];


      for (let i in pay.Result3) {
        state.zSiteInfo.push({sId:pay.Result3[i].cSiteId, sNm:pay.Result3[i].cSiteNm});
      }


      // if (state.zSiteInfo.length == 1){
      //   state.UserInfo.NowSiteId = state.zSiteInfo[0].sId;
      //   state.UserInfo.NowSiteNm = state.zSiteInfo[0].sNm;
      //   localStorage.setItem("UserInfo.NowSiteId", state.UserInfo.NowSiteId);
      //   localStorage.setItem("UserInfo.NowSiteNm", state.UserInfo.NowSiteNm);
      // } else if (state.zSiteInfo.length > 1) {                
      //   state.UserInfo.NowSiteId = localStorage.getItem("UserInfo.NowSiteId");
      //   state.UserInfo.NowSiteNm = localStorage.getItem("UserInfo.NowSiteNm");

      //   if (state.UserInfo.NowSiteId == "") {
      //     state.UserInfo.NowSiteId = state.zSiteInfo[0].sId;
      //     state.UserInfo.NowSiteNm = state.zSiteInfo[0].sNm;
      //     localStorage.setItem("UserInfo.NowSiteId", state.UserInfo.NowSiteId);
      //     localStorage.setItem("UserInfo.NowSiteNm", state.UserInfo.NowSiteNm);
      //   }
      // }      

      state.ShowMenuCheck();        
    },

    loginError(state) {
      state.isLogin = false;
      state.isLoginError = true;
    },

    logout(state) {
      state.isLogin = false;
      state.isLoginError = false;
      state.UserInfo.UserId = "";
      state.UserInfo.UserNm = "";

      localStorage.setItem("UserInfo.UserId", "");
    },   
    
    GetCdGroupInfo(state) {
      state.CdGroupInfo.zNm = ["ALL"];
      state.CdGroupInfo.zId = ["ALL"];
      state.CdGroupInfo.zNmNoAll = [];
      state.CdGroupInfo.zIdNoAll = [];

      var posData = {
        cUserId: state.UserInfo.UserId,        
        cUseCheck: "Y",
      }

      var config = {
        headers: {
          "Authorization": state.UserInfo.Token,
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetCdGroup", posData, config)
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            for (var i = 0; i < res.data.Result.length; i++) {
              state.CdGroupInfo.zNm.push(res.data.Result[i].cCdGroupId + "-" + res.data.Result[i].cCdGroupNm);
              state.CdGroupInfo.zId.push(res.data.Result[i].cCdGroupId);
              state.CdGroupInfo.zNmNoAll.push(res.data.Result[i].cCdGroupId + "-" + res.data.Result[i].cCdGroupNm);
              state.CdGroupInfo.zIdNoAll.push(res.data.Result[i].cCdGroupId);
            }
            if (res.data.Result.length > 0) {
              EventBus.$emit("onCdGroupInfo", "ALL");
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },


    
    
    //유저역할정보 습득
    GetUserRoleInfo(state) {
      state.UserRoleInfo = [];
      
      var posData = {
        cUserId: state.UserInfo.UserId,        
      }

      var config = {
        headers: {
          "Authorization": state.UserInfo.Token,
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetUserRole", posData, config)
        .then(res => {          
          if (res.data.ResultCd === "00") {
            for (var i = 0; i < res.data.Result1.length; i++) {
              state.UserRoleInfo.push({sId:res.data.Result1[i].cUserRoleId, sNm:res.data.Result1[i].cUserRoleNm});              
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },

    
  },
  actions: {
    GetCdGroupInfo({commit}) {
      commit("GetCdGroupInfo");
    },
    GetUserRoleInfo({commit}) {
      commit("GetUserRoleInfo");
    },
    loginErrorfunc({commit}) {
      commit("loginError");
    },
    login({commit}, loginObj) {            
      commit("loginSucess", loginObj);      
      router.push({
        name: "Dashboard"
      });
    },
    logout({commit}) {
      commit("logout");
      router.push({
        name: "Login"
      });
    },
    
    CheckLogin({commit}) {
      setTimeout(() => {
        if (!this.state.UserInfo.isSSO) {          
          var posData = {
            UserId: localStorage.getItem("UserInfo.UserId"),
          }
    
          axios
            .post(BasicInfo.UIL_API + "GetUserInfo", posData, {
              headers: {
                "Authorization": localStorage.getItem("UserInfo.Token")
              }
            })
            .then(res => {
              
              if (res.data.ResultCd === "00") {                
                commit("loginSucess", res.data);                            
                router.push({
                  name: "Dashboard"
                });
              } else if (res.data.ResultCd === "44") {
                router.push({
                  name: "Login"
                });
              }
            }).catch(err => {
              console.log(err)
              router.push({
                name: "Login"
              });
            })
          
        } else {
          console.log("--------------------------------- CheckLogin_SSO");
        }
      }, 10);
      
    },

  },

  modules: {}
});
