<template>
  <!-- 비밀번호 수정 다이올로그-->
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="pwDialog" max-width="600px" v-if="pwDialog">
      <v-card ref="form">
        <v-card-title >
          <span class="font-weight-bold">비밀번호 수정</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cPwd" label="현재 비밀번호" 
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"                        
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cNewPwd" label="신규 비밀번호" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"                        
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cNewPwd1" label="비밀번호 확인" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"                        
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>                     
          <v-spacer></v-spacer>
          <v-btn color="blue darken-2" text @click="save" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">save</v-icon>저장
          </v-btn>
          <v-btn color="grey darken-2" text @click="pwDialog=false" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">cancel</v-icon>취소
          </v-btn>
        </v-card-actions>              
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';
import SHA256 from "sha256";   

export default {
  data: () => ({
    pwDialog: false,
    PWcheck: false,
    show1 : false,
    show2 : false,
    show3 : false,
    editedItem: { 
      cPwd: "",
      cNewPwd: "",
      cNewPwd1: "",
    },
    defaultItem: { 
      cPwd: "",
      cNewPwd: "",
      cNewPwd1: "",
    },
  }),

  created() {
    this.pwDialog = false;
  },

  methods: {
    open() {
      this.pwDialog = true;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    //신규비밀번호 유효성검사
    checkPW() {
      this.PWcheck = true;
      var cUserId = this.$store.state.UserInfo.UserId;
      var cNewPwd = this.editedItem.cNewPwd;
      var check1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{4,30}$/.test(cNewPwd);   //영문,숫자 (4~30자리)
      var check2 = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{4,30}$/.test(cNewPwd);  //영문,특수문자 (4~30자리)
      var check3 = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{4,30}$/.test(cNewPwd);  //영문,특수문자, 숫자 (4~30자리)
      if(!(check1||check2||check3)){
        EventBus.$emit("onShowMsg",true, "신규 비밀번호는 영문+숫자(4자리) 또는 영문+특수기호(4자리) 또는 영문+숫자+특수기호(4자리)로 설정해주세요.");
        this.PWcheck = false;
        return;
      }
      if(/(\w)\1\1/.test(cNewPwd)){
        EventBus.$emit("onShowMsg",true, "신규 비밀번호는 같은 문자를 3번 이상 사용하실 수 없습니다.");
        this.PWcheck = false;
        return;
      }
      if(cNewPwd.search(cUserId)>-1){
        EventBus.$emit("onShowMsg",true, "신규 비밀번호에 아이디가 포함되었습니다.");
        this.PWcheck = false;
        return;
      }
      return;
    },
    
    //비밀번호 수정
    save() {
    // if (this.editedItem.cPwd == "" ) {
    //   EventBus.$emit("onShowMsg",true, "현재 비밀번호를 입력하세요.");
    //   return;
    // }
    if (this.editedItem.cNewPwd == "" ) {
      EventBus.$emit("onShowMsg",true, "신규 비밀번호를 입력하세요.");
      return;
    }
    if (this.editedItem.cNewPwd1 == "" ) {
      EventBus.$emit("onShowMsg",true, "신규 비밀번호를 재입력하세요.");
      return;
    }
    if (this.editedItem.cNewPwd !== this.editedItem.cNewPwd1 ) {
      EventBus.$emit("onShowMsg",true, "신규 비밀번호가 일치하지 않습니다.");
      return;
    }
    this.checkPW()
    if (this.PWcheck == false) {
      return;
    }

    var posData = {
      cUserId : this.$store.state.UserInfo.UserId,
      cPwd : SHA256(this.editedItem.cPwd + "@" + this.$store.state.UserInfo.UserId),
      cNewPwd: SHA256(this.editedItem.cNewPwd + "@" + this.$store.state.UserInfo.UserId),
      }    
    axios
      .post(BasicInfo.UIL_API + "SetPW", posData, {
        headers: {
          Authorization: this.$store.state.UserInfo.Token
        }
      })
      .then(res => {
        if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
        if (res.data.ResultCd === "00") {
          EventBus.$emit("onShowMsg",false, "비밀번호가 변경되었습니다. 다시 로그인해주세요.");
          this.$store.dispatch('logout');
          this.pwDialog = false;
        } else {
          EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
        }
      })
      .catch(err => {
        EventBus.$emit("onShowMsg",true, ""+err);
      });
    },
  },
}
</script>