<template>
  <div>
    <div v-if="isLogin">
      <div id="under_bar"></div>
      <v-footer id="core-footer" absoulte>       
        <v-spacer />                    
        <div class="ml-2 grey--text font-weight-light caption" style="color:#7D7D7D;">COPYRIGHT © {{ new Date().getFullYear() }} 
          <a
            href="https://www.hyundai-autoever.com"
            style="font-weight:bolder; text-decoration:none; color:#888888;"
            target="_blank"
          >{{cTitle}} HYUNDAI AUTOEVER CORP.</a> &nbsp;ALL RIGHTS RESERVED.</div>    
      </v-footer>
    </div>
    <div v-else >
      <v-footer id="core-footer-login" absoulte color="#00000000">                    
        <div class="ml-2 font-weight-light caption" style="color:#7D7D7D;">COPYRIGHT © {{ new Date().getFullYear() }} 
          <a
          href="https://www.hyundai-autoever.com"
            style="font-weight:bolder; text-decoration:none; color:#05A951;"
            target="_blank"
          >{{cTitle}} HYUNDAI AUTOEVER CORP.</a> &nbsp;ALL RIGHTS RESERVED.</div>    
      </v-footer>
    </div>
  </div>
  
</template>


<script>
import BasicInfo from "@/BasicInfo.js";
import {mapState} from "vuex";

export default {
  data: () => ({
    cTitle : "HIoS",
  }),

  computed: {
    ...mapState(["isLogin"]),        
  },


  mounted() {
    this.cTitle = BasicInfo.Title;
  },
};
</script>

<style scoped>
  #under_bar {
    z-index: 1;
    position: relative;
    top : 0px;
    width : 100%;
    height : 1px;
    background:#E0E0E0;    
  }
  #core-footer {
    z-index: 0;
  }
  #core-footer-login {
    z-index: 99;
  }
</style>


