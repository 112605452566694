<template>
  <v-app id="app">        
    <CoreNavigation/>
    <CoreView />
    <CoreFooter />
    <MsgBar ref="MsgBarRef" />
    <PushMessageReq ref="PushMessageReqRef"/>
    <SensorState ref="SensorStateRef"></SensorState>
   </v-app>
</template>

<script>
import router from "@/router/index"
import CoreView from "@/components/core/View.vue";
import CoreFooter from "@/components/core/Footer.vue";
import CoreNavigation from "@/components/core/Navigation.vue";
import SensorState from "@/components/SensorState.vue";

import MsgBar from "@/components/MsgBar.vue";
import PushMessageReq from "@/components/PushMessageReq.vue";
import EventBus from '@/eventBus.js';
import {mapState} from "vuex";
import Vuetify from 'vuetify/lib';
import BasicInfo from "@/BasicInfo.js";

Vuetify.config.silent = true;

window.onkeydown = function() {
	var kcode = event.keyCode;
  
	if(kcode == 116) event.returnValue = false;
}
export default {
  name: 'App',
  components: {    
    CoreView,    
    CoreFooter,    
    CoreNavigation,
    MsgBar,
    PushMessageReq,    
    SensorState,
  },
  
  data: () => ({
    cSiteId : "SJ",
  }),
  
  computed: {
    ...mapState(["isLogin", "UserInfo"]),        
  },

  created() {
    this.cSiteId = BasicInfo.SiteId;


    document.title = BasicInfo.Title;
    this.$vuetify.theme.dark = false;        
    window.addEventListener('beforeunload', () => {
      this.$store.dispatch('logout');
      return null
    })
  },

  mounted() {  
    EventBus.$on("onPushMessageReq", (data) => {    
      this.$refs.PushMessageReqRef.Show(data);      
    });
    EventBus.$on("onErrorSnackbar", (data) => {    
      this.$refs.MsgBarRef.ShowSnackbar(data);
    });
    EventBus.$on("onShowMsg", (result, data) => {    
      this.$refs.MsgBarRef.ShowMsg(result, data);
    });
    EventBus.$on("onShowMsgBox", (sTitle, sMsg) => {    
      this.$refs.MsgBarRef.RunMsgBoxShow(sTitle, sMsg);
    });
    EventBus.$on("onTokenExpired", () => {    
      this.$store.dispatch('logout');
    });
    EventBus.$on("onSensorLog", (sSensorId) => {    
      this.$refs.SensorStateRef.Show(sSensorId);
    });
    EventBus.$on("onMapPos", (cApId) => { 

      try {
        router.push({ name: "Dashboard" });  
      } catch (error) {
        console.log("App(mounted).vue:" + error);
      }
      

      setTimeout(() => {
        EventBus.$emit("onMapPosDashboard", cApId);     
      }, 500);
    });
    EventBus.$on("onLLM_Function", (cType, cKeyword) => { 
      if (cType == "" || cType == "Unknown") {
        EventBus.$emit("onLLM_Chat", cType, cKeyword);     
        return
      }      

      if (cType == "UI") {
// UI 대시보드
        if (cKeyword == "대시보드" || cKeyword == "데시보드") {
          router.push({ name: "Dashboard" });  
        } else if (cKeyword == "모니터링") {
          router.push({ name: "Monitoring" });  
        } else if (cKeyword == "권한관리" || cKeyword == "권한 관리") {
          router.push({ name: "AuthorityInfo" });  
        } else if (cKeyword == "작업자관리" || cKeyword == "작업자 관리") {
          router.push({ name: "WorkerInfo" });  
        } else if (cKeyword == "권한관리" || cKeyword == "권한 관리") {
          router.push({ name: "AuthorityInfo" });  
        } else if (cKeyword == "태그매핑" || cKeyword == "태그 매핑") {
          router.push({ name: "UserTagMappingLog" });  
        } else if (cKeyword == "장비관리" || cKeyword == "장비 관리") {
          router.push({ name: "EquipInfoAp" });  
        }
        return
      }
      

      

      try {
        router.push({ name: "Dashboard" });  
      } catch (error) {
        console.log("App(mounted).vue:" + error);
      }
      

      setTimeout(() => {
        EventBus.$emit("onLLM_Find", cType, cKeyword);     
      }, 500);
    });
  },

  beforeDestroy(){
    EventBus.$off('onPushMessageReq');
    EventBus.$off('onShowMsg');
    EventBus.$off('onErrorSnackbar');
    EventBus.$off('onShowMsgBox');
    EventBus.$off('onTokenExpired');
    EventBus.$off('onSensorLog');
    EventBus.$off('onMapPos');
    EventBus.$off('onLLM_Function');
  },

};
</script>


<style>   
  .background_basic {
    /* background: red; */
    background: #ffffff;    
    overflow:auto; 
    width:100%; 
    height:calc(100vh - 81px);    
  }

  .v-application {
    /* font-family: 'Noto Sans KR', 'sans-serif' !important; */
    font-family: "Pretendard GOV Variable", "Pretendard GOV", sans-serif !important;  
  }

  .v-application .text-subtitle-1, .v-application .subtitle-2, .v-application .caption   {    
    /* font-family: 'Noto Sans KR', 'sans-serif' !important; */
    font-family: "Pretendard GOV Variable", "Pretendard GOV", sans-serif !important;  
  }
  .v-application .text-h5, .v-application .text-h4{
    
    /* font-family: 'Noto Sans KR', 'sans-serif' !important; */
    font-family: "Pretendard GOV Variable", "Pretendard GOV", sans-serif !important;  
  }


  .ol-zoom-in{
    background-color: #888888 !important;    
  }
  .ol-zoom-out{
    background-color: #888888 !important;    
  }
  

  /* .main_image { 
    z-index: 0;
    position: fixed;    
    right: -100px;
    bottom: 0px;      
    width: 70%;    
  }  */

  .table_div { 
    height:5px; 
    background:#FAFAFA;
  }

  
  [role=columnheader] {    
    white-space: nowrap;         
  }
  

  .v-data-table__wrapper{        
    border-bottom: 1px solid #E0E0E0 !important;    
    -webkit-line-clamp: 1;     
  }

  .theme--light.v-data-table .v-data-footer {
    border-top: 0px solid red !important;    
    -webkit-line-clamp: 1;     
  }

  
  td.text-center {            
    border-bottom: 0.5px solid #E0E0E0 !important;    
    -webkit-line-clamp: 1;     
    text-overflow: ellipsis !important;  
  } 

  td.text-start {        
    border-bottom: 0.5px solid #E0E0E0 !important;   
    text-overflow: ellipsis !important;   
  }  

  td.text-end {        
    border-bottom: 0.5px solid #E0E0E0 !important;        
    text-overflow: ellipsis !important;
  }

  td.text-right {        
    border-bottom: 0.5px solid #E0E0E0 !important;        
    text-overflow: ellipsis !important;
  }  

  td.text-left {        
    border-bottom: 0.5px solid #E0E0E0 !important;            
    text-overflow: ellipsis !important;
  }  
  
  .un_use_item {    
    color:#CCCCCC;
    text-decoration: line-through;     
  }

  .un_readed_item {    
    color:#CCCCCC;    
  }

  .un_unreaded_senderror_item {    
    color:#FF0000;    
  }
  
  .v-data-footer__select{    
    height: 40px;
  }

  .v-data-table-header__icon{
    position: relative;
    width: 0px;
    left: 10px;
  }
  
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {        
    border-bottom: 2px solid #EEEEEE !important;    
    font-size: 12px !important;    
  }

  #AppHeader2 {
    z-index: 0;
    Height : 50px;
    width : 100%;    
    background: white;
  }
  
  
  ::-webkit-scrollbar {
    width: 6px;           
    height: 6px;           
  }
  /* ::-webkit-scrollbar {
    width: 13px;           
    height: 13px;           
  } */

  ::-webkit-scrollbar-track {    
    background:#F8F8F8;    
  }

  ::-webkit-scrollbar-thumb {
    background-color: #E8E8E8;    
  }
  
  .v-date-picker-table .v-btn {
    font-size: 13px !important;
  }

  .all_windows{
    z-index:2000; 
    position: absolute; 
    left: 0px; 
    top : 0px; 
    width: 100%; 
    height: 100%;    
  }
  
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .emphasis {
    font-weight: 800;
    color: #ff5722;
    background-color: #ffebee;
    padding: 1.5px 8px;
    border-radius: 4px;    
  }

  .quote-box {
    background: #ffffff; /* 반투명 흰색 배경 */
    border-left: 4px solid #00000080; /* 왼쪽 테두리 색상 */
    border-right: 4px solid #00000080; /* 왼쪽 테두리 색상 */
    border-top: 1px solid #00000080; /* 왼쪽 테두리 색상 */
    border-bottom: 1px solid #00000080; /* 왼쪽 테두리 색상 */
    margin: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
  }

  .quote-box p {
    margin: 0;
  }

</style>

