import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);


const rejectAuthUser = (to, from, next) => {
  if (store.state.isLogin === true) {
    next("/");
  } else {
    next();
  }
};


async function checkAuthUser(to, from, next) {  
  if (store.state.isLogin == false) {
    next("/Login");
  } else {
    if (to.name == "Dashboard") {
      next();
    } else if (to.name == "EnterReq") {
      next();
    } else {
      var iRet = await store.state.iGetPromgramUserRoleInfo(getProgramNm(to.name), to.name, from.name);
      if (iRet == 0) {
        next();
      } else if(iRet == -1) {
        next("/NoPermission");
      } else if(iRet == -2) {        
        store.state.isLogin = false;
        store.state.isLoginError = false;
        store.state.UserInfo.UserId = "";
        store.state.UserInfo.UserNm = "";        
        next("/Login");
      } 
    }
  }
}


function getProgramNm(name) {
  for(var i =0; routes.length; i++){
    if (routes[i].name == name) {
      return routes[i].caption;
    }
  }
  return "(알 수 없음)";
}

//-----------------------------------------------------------------------------//
//페이지 추가시, DB의 programinfo table(CRUD)에 추가 필요

const routes = [
  {
    path: "/NoPermission",
    caption: "권한없음",
    name: "NoPermission",    
    component: () => import("../views/NoPermission.vue")
  }, 
  {
    path: "/SSOLink",
    caption: "SSOLink",
    name: "SSOLink",    
    component: () => import("../views/SSOLink.vue")
  }, 
  {
    path: "/",
    name: "Dashboard",
    caption: "홈",
    beforeEnter: checkAuthUser,
    component: () => import("../views/Dashboard.vue")
  },
  {
    path: "/Monitoring",
    name: "Monitoring",
    caption: "모니터링",
    // beforeEnter: checkAuthUser,
    component: () => import("../views/Monitoring.vue")
  },
  {
    path: "/AuthorityInfo",
    name: "AuthorityInfo",
    caption: "권한관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/AuthorityInfo.vue")
  },  
  {
    path: "/AlarmSetting",
    name: "AlarmSetting",
    caption: "알림설정",
    beforeEnter: checkAuthUser,
    component: () => import("../views/AlarmSetting.vue")
  },
  {
    path: "/IdInfo",
    name: "IdInfo",
    caption: "계정관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/IdInfo.vue")
  },    
  {
    path: "/WorkerInfo",
    name: "WorkerInfo",
    caption: "작업자관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/WorkerInfo.vue")
  },
  {
    path: "/TagMapping",
    name: "TagMapping",
    caption: "태그매핑",
    beforeEnter: checkAuthUser,
    component: () => import("../views/TagMapping.vue")
  },
  {
    path: "/UserTagMappingLog",
    name: "UserTagMappingLog",
    caption: "태그매핑 이력조회",    
    beforeEnter: checkAuthUser,
    component: () => import("../views/UserTagMappingLog.vue")
  },
  {
    path: "/EquipSettingAp",
    name: "EquipSettingAp",
    caption: "AP",    
    beforeEnter: checkAuthUser,
    component: () => import("../views/EquipSettingAp.vue")
  },
  {
    path: "/EquipSettingTag",
    name: "EquipSettingTag",
    caption: "Tag",    
    beforeEnter: checkAuthUser,
    component: () => import("../views/EquipSettingTag.vue")
  },
  {
    path: "/EquipInfoAp",
    name: "EquipInfoAp",
    caption: "장비관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EquipInfoAp.vue")
  },
  // {
  //   path: "/EquipInfoTag",
  //   name: "EquipInfoTag",
  //   caption: "장비관리",
  //   beforeEnter: checkAuthUser,
  //   component: () => import("../views/EquipInfoTag.vue")
  // },
  {
    path: "/ZoneInfo",
    name: "ZoneInfo",
    caption: "구조정보관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/ZoneInfo.vue")
  },


  {
    path: "/APInfo",
    name: "APInfo",
    caption: "AP위치관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/APInfo.vue")
  },
  {
    path: "/ZoneUserMapping",
    name: "ZoneUserMapping",
    caption: "구역-직원 매핑",
    beforeEnter: checkAuthUser,
    component: () => import("../views/ZoneUserMapping.vue")
  },    
  {
    path: "/EnterSiteList",
    name: "EnterSiteList",
    caption: "구역출입목록",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EnterSiteList.vue")
  },
  {
    path: "/EnterMapGroupList",
    name: "EnterMapGroupList",
    caption: "단지출입목록조회",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EnterMapGroupList.vue")
  },
  // {
  //   path: "/EnterGroupLog",
  //   name: "EnterGroupLog",
  //   caption: "구역그룹출입목록",
  //   beforeEnter: checkAuthUser,
  //   component: () => import("../views/EnterGroupLog.vue")
  // },
  {
    path: "/EnterUserLog",
    name: "EnterUserLog",
    caption: "출입인원현황조회",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EnterUserLog.vue")
  },
  {
    path: "/EventLog",
    name: "EventLog",
    caption: "이벤트 이력관리",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EventLog.vue")
  },
  {
    path: "/LogView",
    name: "LogView",
    caption: "이벤트 로그",
    beforeEnter: checkAuthUser,
    component: () => import("../views/LogView.vue")
  },
  {
    path: "/LogEventView",
    name: "LogEventView",
    caption: "API 로그",
    beforeEnter: checkAuthUser,
    component: () => import("../views/LogEventView.vue")
  },
  {
    path: "/SOSResView",
    name: "SOSResView",
    caption: "비상호출 조치결과",
    beforeEnter: checkAuthUser,
    component: () => import("../views/SOSResView.vue")
  },  
  {
    path: "/EnterReqAgent",
    name: "EnterReqAgent",
    caption: "대리출입신청",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EnterReqAgent.vue")
  },
  {
    path: "/EnterPermission",
    name: "EnterPermission",
    caption: "출입승인",
    beforeEnter: checkAuthUser,
    component: () => import("../views/EnterPermission.vue")
  },
  {
    path: "*",
    name: "NotFound",
    caption: "NotFound",
    beforeEnter: checkAuthUser,
    component: () => import("../views/Dashboard.vue")
  },
  {
    path: "/Login",
    name: "Login",
    caption: "로그인",
    beforeEnter: rejectAuthUser,
    component: () => import("../views/Login.vue")
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
});

export default router;