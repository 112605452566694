<template>
  <v-main> 
    <!-- 라우터에 의해 각페이지를 보여줌 -->      
    <div id="core-view" >
      <v-fade-transition mode="out-in">
        <router-view> 
          
        </router-view>
      </v-fade-transition>
    </div>
  </v-main>
</template>

<script>
export default {
    
  }
</script>

<style>
#core-view {
  padding-top: 0px;
  padding-bottom: 0px;   
}
</style>
