var noClickTime = new Date();
module.exports = {
  StrToFloat(s){
    return parseFloat(s);
  },

  sGetNmRecord(zRecord, sId){
    for(var i = 0; i < zRecord.length; i++){
      if (zRecord[i].sId == sId) {
        return zRecord[i].sNm;
      }
    }
    return "";
  },


  bInCheck(zRecord, sData){
    for(var i = 0; i < zRecord.length; i++){
      if (zRecord[i] == sData) {
        return true;
      }
    }
    return false;
  },

  sGetId(s) {    
    // return s.substring(0, 6);   
    if (s == "") return s;
    if (s == "ALL") return s;
    if (s == "NONE") return s;
    
    var idx = s.indexOf("-");

    if (idx == -1) {
      return "";
    } else {
      return s.substring(0, idx);
    }
  },

  sGetNm(s) {    
    if (s == "") return s;
    var idx = s.indexOf("-");
    if (idx == -1) {
      return "";
    } else {
      return s.substring(idx+1, s.length);
    }
  },

  sBoolToStr(data) {
    if (data) {
      return "Y"
    } else {
      return "N"
    }    
  },

  sFormatDate(date) 
  { 
    var d = new Date(date), month = '' + (d.getMonth() + 1), 
    day = '' + d.getDate(), year = d.getFullYear(); 
    if (month.length < 2) month = '0' + month; 
    if (day.length < 2) day = '0' + day; 
    return [year, month, day].join('-');
  },

  pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  },

  sFormatFloat(num){		    
    if(num==0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = (num + '');
  
    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');  
    return n;
  }, 

  sNowDate(){		    
    var date = new Date(); 
    var year = date.getFullYear(); 
    var month = new String(date.getMonth()+1); 
    var day = new String(date.getDate()); 

    // 한자리수일 경우 0을 채워준다. 
    if(month.length == 1){ 
      month = "0" + month; 
    } 
    if(day.length == 1){ 
      day = "0" + day; 
    } 

    return year + "" + month + "" + day;
  },  

  sNowDate2(){		    
    var date = new Date(); 
    var year = date.getFullYear(); 
    var month = new String(date.getMonth()+1); 
    var day = new String(date.getDate()); 

    // 한자리수일 경우 0을 채워준다. 
    if(month.length == 1){ 
      month = "0" + month; 
    } 
    if(day.length == 1){ 
      day = "0" + day; 
    } 

    return year + "-" + month + "-" + day;
  },  

  s10YearSel(){
    var today = new Date();
    var daysago = 365*10;
    var oldday = new Date(today-(3600000*24*daysago));
    return oldday;
  },

  sYearSel(){
    var today = new Date();
    var daysago = 365;
    var oldday = new Date(today-(3600000*24*daysago));
    return oldday;
  },
  
  sMonthSel(){
    var today = new Date();
    var daysago = 30;
    var oldday = new Date(today-(3600000*24*daysago));
    return oldday;
  },

  sTomorrowSel(){
    var today = new Date();    
    var oldday = new Date(today.setDate(today.getDate()+1));
    return oldday;
  },

  getState (cSendFlag, cCode) {      
    if (cSendFlag === "OK") return '●'
    if (cSendFlag === "OK2") return '▲'      
    if (cSendFlag === "ING") return '...'      
    else return "X(" + cCode + ")";
  },

  fStrToFloat(s) {
    if (s == "") {return 0}
    if (s == null) {return 0}

    return parseFloat(s);
  },

  clickCheckTime(){
    noClickTime = new Date();
  },

  bDbclickCheck(){
    var n = (new Date())-noClickTime;    
     return (n < 200);
  },
  getExtension(fileName) {    
    var fileLength = fileName.length;    
    //substring 메서드는 start에서 end까지(end는 포함 안 함) 부분 문자열을 포함하는 문자열을 반환합니다. 
    var lastDot = fileName.lastIndexOf('.'); 
    var fileExtension = fileName.substring(lastDot+1, fileLength);     
    return fileExtension;
  },  

  checkDate(strDate){
    var chkdate=Date.parse(strDate)
    if (isNaN(chkdate)==false) {
        var d=new Date(chkdate);
        console.log(d);
        return true;
    } else {
        return false;
    }
  },

  sGetFileType(s) {        
    if (s == "") {
      return s;
    }
    var idx = 0;
    idx = s.indexOf("video");
    if (idx > -1) { return "10-동영상";}

    idx = s.indexOf("image");
    if (idx > -1) { return "20-이미지";}
    
    idx = s.indexOf("audio");
    if (idx > -1) { return "30-음악";}    

    return "00-알 수 없음";
  },

  iDateGap(date1, date2) {      
    var dateArray1 = date1.split("-");  
    var dateArray2 = date2.split("-");  
      
    var dateObj1 = new Date(dateArray1[0], Number(dateArray1[1])-1, dateArray1[2]);  
    var dateObj2 = new Date(dateArray2[0], Number(dateArray2[1])-1, dateArray2[2]);  
      
    var betweenDay = (dateObj2.getTime() - dateObj1.getTime())/1000/60/60/24;  
      
    return(betweenDay);  
  },

  GetExcel(org, header) {    
    var ret = [];
    
    for(let i = 0; i < org.length; i++){
      var a = {}
      for(let j = 0; j < header.length; j++){
        if (header[j].text != '작업')
          a[header[j].text] = org[i][header[j].value];
      }
      ret.push(a);
    }

    return ret;
  },
  
  getExcelFile(response, fileName) {
    // let result = document.createElement('a');

    // console.log((new Date()).yyyymmdd());

    // let blob = new Blob([response], {type: response.headers['content-type']})
    let blob = new Blob(response, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

    //파일이름이 없을 경우 기본 파일이름을 셋팅한다
    // 기본 파일이름 : export_yyyymmdd.xlsx
    
    // fileName = fileName+'_'+(new Date()).yyyymmdd()+'.xlsx';
    fileName = fileName+'.xlsx';

    //this.fileCheck(fileName);

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_self';
    link.download = fileName;
    link.click(); 
  },  
  
  timeForToday(value) {
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (betweenTime < 1) return '방금전';
    if (betweenTime < 60) {
        return `${betweenTime}분전`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 2) {
        return `어제`;
    }
    if (betweenTimeDay < 7) {
        return `${betweenTimeDay}일전`;
    }
    return value;
    // return `${Math.floor(betweenTimeDay / 365)}년전`;
  },

  timeForTodayOnlyWith(value) {    
    if (value == null || value == "") {
      return "--";
    }
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (betweenTime < 1) return '방금전';
    if (betweenTime < 60) {
        return `${betweenTime}분전`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 2) {
        return `어제`;
    }
    if (betweenTimeDay < 30) {
        return `${betweenTimeDay}일전`;
    }
    
    if (betweenTimeDay < 365) {
        const betweenTimeMonth = Math.floor(betweenTime / 60 / 24 / 30);
        return `${betweenTimeMonth}개월전`;
    }

    return `${Math.floor(betweenTimeDay / 365)}년전`;      
  },


  ScrollDown(){
    if (window.scrollY == 0) {
      window.scrollTo(0,1);        
    }    
  },
  leadingZeros(n, digits) {
    var zero = '';
    n = n.toString();
  
    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        zero += '0';
    }
    return zero + n;
  },
  

  getNowTime() {
    var d = new Date();
    var s =
      // leadingZeros(d.getFullYear(), 4) + '-' +
      // leadingZeros(d.getMonth() + 1, 2) + '-' +
      // leadingZeros(d.getDate(), 2) + ' ' +
  
      this.leadingZeros(d.getHours(), 2) + ':' +
      this.leadingZeros(d.getMinutes(), 2);
      // leadingZeros(d.getSeconds(), 2);
  
    return s;
  },


    
  sDateTime(d) {    
    var s =
      this.leadingZeros(d.getFullYear(), 4) + '-' +
      this.leadingZeros(d.getMonth() + 1, 2) + '-' +
      this.leadingZeros(d.getDate(), 2) + ' ' +
  
      this.leadingZeros(d.getHours(), 2) + ':' +
      this.leadingZeros(d.getMinutes(), 2) + ':' +
      this.leadingZeros(d.getSeconds(), 2);
  
    return s;
  },
  
  
};