<template>    
  <v-dialog v-model="dialog" max-width="1024">
    <v-card style="height:calc(100vh - 200px)">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          작업자 선택
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>          
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-0 mt-6"  
            label="Search"
            placeholder="검색어를 입력하세요."     
            persistent-placeholder       
            Regular
            ref="NowSearchKeyWordRef"
            v-on:keyup.enter="Search()"
          ></v-text-field>        
          <v-btn  text icon  
          class="ml-n9"                
            @click="Search()"            
          ><v-icon>search</v-icon>
          </v-btn>  
        </v-toolbar>
        <v-divider></v-divider>      
      <v-data-table :items-per-page="15" 
        fixed-header 
        v-model="selected"
        :headers="headers" 
        :items="Records" 
        item-key="cUserId"      
        show-select        
        height="calc(100vh - 345px)"
      >            
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="blue darken-1" text @click="SelectData()"
          ><v-icon class="mr-2">check_circle</v-icon>선택</v-btn>        
        <v-btn color="grey darken-2" text @click="closePage()"
          ><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
// import Util from "../Util.js";
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';

export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      sDate : "",      
      model : null,      
      cIsDefaultCorp : "",      
      cOnlyCorp : "",
      selected: [],
      cNowSearchKeyWord : "",
      headers: [        
        { class: "font-weight-bold subtitle-2",  text: "아이디", value: "cUserId", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "회사", value: "cCorpNm", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "부서", value: "cPartNm", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "직책", value: "cPantLev", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "이름", value: "cUserNm", width: 120 },      
        { class: "font-weight-bold subtitle-2",  text: "전화번호", value: "cTel", width: 120 },        
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage: function() {                
      this.dialog = false;
    },
    getColor(cSendFlag) {
      if (cSendFlag === "N") return "#ff4862";
      else return "#70bbfe";
    },    

    getColor2(cSendFlag) {
      if (cSendFlag === "Y") return "#ff4862";
      else return "#70bbfe";
    },    
    Show: function(cOnlyCorp, cCorpNm) {             
      this.selected = [];
      this.Records = [];      
      this.cNowSearchKeyWord = "";
      this.dialog = true;            
      this.cOnlyCorp = cOnlyCorp;      
      this.cCorpNm = cCorpNm;      

      setTimeout(() => {
        this.$refs.NowSearchKeyWordRef.focus();  

        if (this.cOnlyCorp != "") {
          this.Search();
        }
      }, 300);      
    },
    SelectData(){
      var ZMember = [];
      for (var i = 0; i < this.selected.length; i++){                
        ZMember.push({ 
          cUserId : this.selected[i].cUserId,
          cUserNm : this.selected[i].cUserNm,          
          cCorpId : this.selected[i].cCorpId,          
          cCorpNm : this.selected[i].cCorpNm,          
          cTagId : this.selected[i].cTagId,          
        });
                      }
      if (ZMember.length ==0) {
        EventBus.$emit("onShowMsg",true, "No data selected.") 
        return;
      }      
      this.$emit("onSelect", ZMember);
      this.dialog = false;   
    },    
    Search(){
      this.Records = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cNowSearchKeyWord : this.cNowSearchKeyWord,                
        cIsDefaultCorp : "",        
        cOnlyCorp : this.cOnlyCorp,        
        //iron28
        cCorpNm : this.$store.state.UserInfo.CorpNm,        
      };

      // console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "GetSelectUserInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;
            for(var i = 0; i < this.Records.length; i++){
              this.Records[i].bUse = this.Records[i].cUse == "Y";              
            }            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },
  }
};
</script>
